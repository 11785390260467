.mv {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
}

.logo {
  position: absolute;
  left: 50%;
  top: calc(50% - 20px);
  margin-left: -60px;
  opacity: 0;
  animation: logoAnim 3s ease-out forwards;
  img {
    width: 120px;
    height: auto;
  }
}

.canvas-container {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  canvas {
    width: 100%;
    height: 100%;
  }
}

.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;

  canvas {
    width: 100%;
    height: 100%;
  }
}

.detail {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 48px;
  text-align: right;
  color: #333;
  z-index: 11;
  bottom: 30%;
  @media screen and (max-width: 768px) {
    padding-right: 5%;
  }

  &__text {
    margin: 0;
    font-size: 1.2rem;
    line-height: 2;
    height: 4em;
  }
}

.skill {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 1.2rem;
  z-index: 1;
  color: #333;

  &__list {
    margin: 0;
    padding: 0 0 0 20px;
  }

  &__item {
    height: 1em;
    line-height: 1;
    margin-bottom: 1em;
    padding-left: 16px;
    list-style: none;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '-';
    }
  }
}

.company-info {
  padding: 24px;
  margin-top: 100px;
  table {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-collapse: collapse;
    th, td {
      font-weight: normal;
      padding: 2em 1em;
      font-size: 1.4rem;
      @media screen and (max-width: 768px) {
        padding: 1em;
        font-size: 1.2rem;
      }
    }
    th {
      text-align: left;
      padding-right: 1.5em;
      vertical-align: top;
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        padding-right: 1em;
      }
      br {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
    }
    td {
      text-align: left;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0 0 0 1em;
      margin: 0;
      list-style: none;
      position: relative;
      &::before {
        content: "・";
        position: absolute;
        left: -.4em;
      }
      + li {
        margin-top: 8px;
      }
    }
  }
}

.scroll {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}

.copyright {
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 40px;
  margin-top: 80px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    padding-bottom: 20px;
  }
}

@keyframes cursorAnim {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes logoAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#magic {
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: block;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
  &.active {
    animation: logoAnim 1s 1.2s ease-out forwards;
  }
}

.pointer {
  position: fixed;
  top: 120%;
  left: 50%;
  transform: translate(-4px, -4px);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0,0,0, .5);
  @media screen and (max-width: 768px) {
    display: none;
  }
}